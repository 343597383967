<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{
        width: $isMobile() ? '100vw' : '45vw',
        position: 'relative',
        'min-height': '15vh',
      }"
      :header="'Alterar Estado da Lead'"
      :modal="true"
      :closable="false"
    >
      <form
        name="crmProposalsChangeStatus"
        class="p-col-12"
        @submit.prevent="setStatus"
        style="min-height: 45vh"
      >
        <Loading
          :active.sync="showLoader"
          :isFullPage="false"
          :zIndex="9000"
        ></Loading>
        <p v-if="!selected">
          <b>Lead {{ proposalId }} | {{ proposalSubject }}</b>
        </p>
        <p
          v-else
          v-for="proposalSelected in selected"
          :key="proposalSelected.id"
        >
          <b>Lead {{ proposalSelected.id }} | {{ proposalSelected.subject }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="proposalStatus"
                :options="statusList"
                :filter="true"
                :optionLabel="'name'"
                :optionValue="'id'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('proposalStatus') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="proposalStatus">Selecionar Estado da Lead</label>
            </span>
            <small
              v-if="errors.has('proposalStatus')"
              class="p-error"
              role="alert"
            >
              Estado é obrigatório
            </small>
            <small v-if="errors.has('noFiles')" class="p-error" role="alert">
              Tem de Adiconar/Gravar os Ficheiros
            </small>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-3" v-if="current == 7">
            <span
              class="p-float-label"
              :style="{ top: '-0.75rem', left: '0rem', fontSize: '12px' }"
            >
              <label for="leadCategory">Razão de Não Vendido</label>
            </span>
            <small v-if="errors.has('reason')" class="p-error" role="alert">
              Razão de não vendido é obrigatório
            </small>
          </div>
          <div
            class="p-field p-col-12 p-md-12 p-grid p-ml-1"
            v-if="current == 7"
          >
            <div
              v-for="reason of reasonRejectList"
              :key="reason.id"
              class="p-col-2 p-md-6 p-mt-1"
            >
              <Checkbox
                :id="reason.id"
                name="reason"
                :value="reason.id"
                v-model="reasonReject"
              />
              <label :for="reason.id" class="p-ml-2">{{ reason.name }}</label>
            </div>
          </div>
          <div class="p-field p-col-12 p-md-12 p-mt-4" v-if="current == 7">
            <span class="p-float-label">
              <Textarea
                v-model="reasonRejectComment"
                :autoResize="true"
                rows="5"
                v-bind:class="['form-control']"
                :id="'reasonRejectComment'"
                :name="'reasonRejectComment'"
              />
              <label for="reasonRejectComment">Comentários</label>
            </span>
          </div>
          <div class="p-col-12" v-if="current == 6 || current == 8">
            <SaleForm
              v-for="files in filesSales"
              :key="files.index"
              :index="files.index"
              v-on:saved="saveSaleInfo"
              v-on:remove="removeSaleInfo"
            />
            <div class="p-col-12">
              <Button
                v-if="allFilesAdded"
                icon="pi pi-plus"
                label="Anexar mais Ficheiros"
                class="p-button p-button-primary"
                @click="newFile"
              />
            </div>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="setStatus"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import crmService from "../../services/crm.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SaleForm from "./ChangeStatusSaleForm.vue";

export default {
  name: "CrmChangeStatus",
  components: {
    Loading,
    SaleForm,
  },
  props: [
    "showToast",
    "show",
    "statusId",
    "proposalId",
    "proposalSubject",
    "selected",
  ],
  watch: {
    statusId() {
      this.showLoader = false;
      this.$validator.pause();
      this.$validator.reset();
      this.current =
        !this.selected || this.selected == undefined ? this.statusId : null;
    },
  },
  data() {
    return {
      current: !this.selected ? this.statusId : null,
      statusList: [],
      reasonRejectList: [],
      reasonReject: null,
      reasonRejectComment: null,
      showLoader: false,
      filesIndex: 1,
      filesSales: [{ saved: false, index: 0 }],
      allFilesAdded: false,
    };
  },
  async created() {
    this.statusList = await crmService.getStatusList();
    this.reasonRejectList = await crmService.getReasonReject();
  },
  methods: {
    cancel() {
      this.reasonReject = null;
      this.reasonRejectComment = null;
      this.filesSales = [{ saved: false }];
      return this.$emit("cancel");
    },
    setStatus() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        if (
          this.current == 7 &&
          (this.reasonReject == null || this.reasonReject == "")
        ) {
          this.$validator.errors.add({
            field: "reason",
            msg: "Razão de não vendido é obrigatório",
          });
          this.loading = false;
          return;
        }
        if (this.current == 6 || this.current == 8) {
          if (this.filesSales.length == 0 || !this.allFilesAdded) {
            this.$validator.errors.add({
              field: "noFiles",
              msg: "Tem de Adiconar/Gravar os Ficheiros",
            });
            this.loading = false;
            return;
          }
        }
        this.$validator.pause();
        this.$validator.reset();
        if (this.selected != null) {
          return this.setStatusMultipleProposals();
        }
        this.showLoader = true;
        let params = new FormData();
        params.append("status_id", parseInt(this.current));
        if (this.current == 7) {
          params.append(
            "reason_reject",
            this.reasonReject != null ? this.reasonReject.join(";") : null
          );
          params.append("reason_reject_comment", this.reasonRejectComment);
        } else if (this.current == 6 || this.current == 8) {
          for (const file of this.filesSales) {
            if (file.saved) {
              params.append("clientBudgetFile[]", file.clientBudgetFile);
              params.append("fileCosts[]", file.fileCosts);
              params.append("coments[]", file.coments);
            }
          }
        }
        return crmService
          .setStatus(this.proposalId, params)
          .then((response) => {
            this.showLoader = false;
            this.reasonReject = null;
            this.reasonRejectComment = null;
            this.filesSales = [{ saved: false }];
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o estado da proposta",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changedProposalStatus", {
                status: "error",
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Estado da proposta alterada com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changedProposalStatus", {
              status: "ok",
              result: response,
            });
          });
      });
    },
    async setStatusMultipleProposals() {
      let noErrors = true;
      let res = null;
      this.showLoader = true;
      let params = new FormData();
      params.append("status_id", parseInt(this.current));
      if (this.current == 7) {
        params.append(
          "reason_reject",
          this.reasonReject != null ? this.reasonReject.join(";") : null
        );
        params.append("reason_reject_comment", this.reasonRejectComment);
      } else if (this.current == 6 || this.current == 8) {
        for (const file of this.filesSales) {
          if (file.saved) {
            params.append("clientBudgetFile[]", file.clientBudgetFile);
            params.append("fileCosts[]", file.fileCosts);
            params.append("coments[]", file.coments);
          }
        }
      }
      for (const proposal of this.selected) {
        params.status_id = parseInt(this.current);
        await crmService.setStatus(proposal.id, params).then((response) => {
          if (!response) {
            return (noErrors = false);
          }
          res = response;
        });
      }
      this.showLoader = false;
      this.reasonReject = null;
      this.reasonRejectComment = null;
      this.filesSales = [{ saved: false }];

      if (!noErrors) {
        if (this.showToast) {
          this.$toast.add({
            severity: "error",
            summary: "Erro ao alterar o estado da lead",
            detail: "",
            life: 3000,
          });
        }
        return this.$emit("changedProposalStatus", {
          status: "error",
        });
      }

      if (this.showToast) {
        this.$toast.add({
          severity: "success",
          summary: `Estado da lead alterada com sucesso`,
          detail: ``,
          life: 3000,
        });
      }
      return this.$emit("changedProposalStatus", {
        status: "ok",
        result: res,
      });
    },
    allFilesAreAdded() {
      return (this.allFilesAdded = this.filesSales.every(
        (file) => file.saved == true
      ));
    },
    saveSaleInfo(info) {
      this.filesSales[info.index] = info;
      return this.allFilesAreAdded();
    },
    removeSaleInfo(info) {
      const areYou = (aux) => {
        return aux.index == info;
      };
      this.filesSales.splice(this.filesSales.findIndex(areYou), 1);
      return this.allFilesAreAdded();
    },
    newFile() {
      this.filesSales.push({ saved: false, index: this.filesIndex });
      this.filesIndex++;
      return this.allFilesAreAdded();
    },
  },
};
</script>
